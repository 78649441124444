.collapsible-grid-container.callout {
  border: 0;
  padding: 30px 36px;
  margin-bottom: 0; }

.collapsible-grid-container .collapsible-grid-divider {
  width: 100%;
  border-bottom: 2px solid #d9d9d9;
  margin-top: 40px;
  margin-bottom: 40px; }
