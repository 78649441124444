.titled-modal.reveal.minor {
  max-width: 500px !important; }
  @media screen and (max-width: 640px) {
    .titled-modal.reveal.minor {
      max-width: 100% !important; } }

.titled-modal.reveal .callout {
  margin: 0;
  background-color: #ebebeb; }
  .titled-modal.reveal .callout .title-bar {
    background-color: #2b5898; }
    .titled-modal.reveal .callout .title-bar .titled-panel-title {
      width: 100%;
      text-align: center; }
