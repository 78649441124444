.prism-radio {
  display: inline; }
  .prism-radio input.prism-radio-input {
    cursor: pointer;
    margin: 0;
    height: 20px; }
    .prism-radio input.prism-radio-input:disabled {
      cursor: not-allowed !important; }
  .prism-radio label.prism-radio-label {
    cursor: pointer !important;
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px; }
    .prism-radio label.prism-radio-label.disabled {
      cursor: not-allowed !important; }
  .prism-radio input.prism-radio-input:not(old) {
    display: inline-block;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 36px; }
  .prism-radio input.prism-radio-input:not(old) + label.prism-radio-label {
    display: inline-block;
    margin-left: -30px;
    padding-left: 35px;
    line-height: 36px;
    background: url("./images/checkboxes/radio-off.svg") no-repeat; }
  .prism-radio input.prism-radio-input:not(old):checked + label.prism-radio-label {
    background: url("./images/checkboxes/radio-on.svg") no-repeat; }
  .prism-radio input.prism-radio-input:not(old):disabled + label.prism-radio-label {
    opacity: 0.4; }
