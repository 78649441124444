.edit-member .edit-member-content {
  margin: 0; }
  .edit-member .edit-member-content .org-name-header {
    color: #197dc2;
    font-size: 18px;
    font-weight: bold; }
  .edit-member .edit-member-content .org-roles-header {
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 600; }
  .edit-member .edit-member-content .profile-panel {
    margin-top: 40px; }
