.magic-link .next-route-btn {
  margin-top: 20px;
  min-width: 210px; }

.magic-link .welcome {
  margin-bottom: 40px; }

.magic-link .icon {
  margin-top: -8px; }

.magic-link .icon-description {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  margin-left: 20px; }

.magic-link .message {
  margin-top: 40px; }
