.prove-it-modal .prove-it-modal-content .prove-it-message {
  margin-top: 14px;
  font-size: 12px; }

.prove-it-modal .prove-it-modal-content .prove-it-input-container {
  margin-top: 40px; }

.prove-it-modal .prove-it-modal-content .prove-it-text-input {
  height: 36px;
  font-size: 13px; }

.prove-it-modal .prove-it-modal-content .prove-it-buttons {
  margin-top: 50px; }
