.change-pw-modal .instructions {
  font-size: 12px;
  margin-bottom: 7px; }

.change-pw-modal .error-box {
  margin-top: 20px;
  min-height: 20px;
  font-size: 12px; }

.change-pw-modal .btn-container {
  margin-top: 20px; }
