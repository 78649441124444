.created-account .account-created {
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px; }

.created-account p.instructions {
  font-size: 13px;
  padding-top: 60px; }
  .created-account p.instructions a {
    color: #72cef5; }
  .created-account p.instructions.bottom-instructions {
    padding-top: 189px; }
    @media screen and (max-width: 1024px) {
      .created-account p.instructions.bottom-instructions {
        padding-top: 60px; } }

.created-account .resend-btn {
  margin-top: 20px;
  min-width: 150px; }

.created-account .login-btn {
  margin-top: 20px;
  min-width: 150px; }
