.login {
  color: #000000; }
  .login .marketing-right .login-heading {
    margin-top: 127px;
    font-size: 20px;
    color: #163e78;
    padding-bottom: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 1024px) {
      .login .marketing-right .login-heading {
        font-size: 16px;
        margin-top: 36px; } }
  .login .marketing-right .login-inputs {
    margin-top: 32px; }
  .login .marketing-right .small-link-box {
    margin-top: 37px; }
    .login .marketing-right .small-link-box a {
      display: inline-block;
      margin-bottom: 26px;
      font-size: 13px;
      color: #000000; }
      .login .marketing-right .small-link-box a:last-child {
        margin-bottom: 0; }
  .login .marketing-right .button-box {
    margin-top: 18px;
    text-align: right; }
    .login .marketing-right .button-box .login-btn {
      width: 148px; }
  .login .marketing-right .error-box {
    margin-top: 32px; }
    .login .marketing-right .error-box .error {
      color: #de4949;
      font-weight: 600; }
  .login .marketing-right .forgot-username-message {
    margin-top: 22px;
    color: #163e78;
    font-weight: bold; }
