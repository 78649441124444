.notification-item.callout {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 12px;
  padding: 0;
  border: 0;
  border-radius: 0;
  word-wrap: break-word; }
  .notification-item.callout .new-notification-marker {
    display: inline; }
    .notification-item.callout .new-notification-marker .new-notification-marker-icon {
      width: 14px;
      height: 14px;
      position: absolute;
      left: -43px;
      margin-top: -5px; }
      @media screen and (max-width: 1320px) {
        .notification-item.callout .new-notification-marker .new-notification-marker-icon {
          left: 15px; } }
  .notification-item.callout .notification-info-box {
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 32px;
    word-wrap: break-word; }
    .notification-item.callout .notification-info-box .cell {
      font-size: 12px;
      letter-spacing: -0.3px;
      align-self: center; }
      .notification-item.callout .notification-info-box .cell.middle-row-cell {
        padding-top: 53px;
        padding-bottom: 53px; }
        @media screen and (max-width: 640px) {
          .notification-item.callout .notification-info-box .cell.middle-row-cell {
            padding-top: 20px;
            padding-bottom: 20px; } }
  .notification-item.callout .action-available-box {
    display: inline-block; }
    .notification-item.callout .action-available-box .action-available-icon {
      width: 19px;
      height: 19px;
      color: #9b9b9b;
      margin-left: 27px; }
  .notification-item.callout .notification-icon {
    height: 40px;
    width: 40px;
    max-width: 40px; }
  .notification-item.callout .heading {
    font-weight: bold;
    color: #9b9b9b;
    letter-spacing: -0.3px; }
  .notification-item.callout .toggle-expansion {
    font-size: 12px;
    color: #4a4a4a;
    font-weight: 600;
    letter-spacing: -0.3px; }
