.wizard-box {
  background-color: #197dc2;
  display: table;
  color: #ffffff;
  height: 100vh;
  width: 100vw; }
  .wizard-box a {
    color: #34bbf4; }
    .wizard-box a:hover {
      color: #0daef1; }
  .wizard-box .wizard-box-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    max-width: 100vw; }
    @media screen and (min-width: 0) {
      .wizard-box .wizard-box-content .cell.wizard-cell-full-width {
        width: 90%;
        max-width: 480px;
        margin-left: 5%;
        margin-right: 5%; } }
    @media screen and (min-width: 640px) {
      .wizard-box .wizard-box-content .cell.wizard-cell-full-width {
        width: 480px;
        max-width: 480px;
        margin-left: 10%;
        margin-right: 10%; } }
    @media screen and (min-width: 1024px) {
      .wizard-box .wizard-box-content .cell.wizard-cell-full-width {
        width: 840px;
        max-width: 840px;
        margin-left: 20px;
        margin-right: 20px; } }
    @media screen and (min-width: 1200px) {
      .wizard-box .wizard-box-content .cell.wizard-cell-full-width {
        width: 1040px;
        max-width: 1040px;
        margin-left: 40px;
        margin-right: 40px; } }
    @media screen and (min-width: 1440px) {
      .wizard-box .wizard-box-content .cell.wizard-cell-full-width {
        width: 1120px;
        max-width: 1120px;
        margin-left: 80px;
        margin-right: 80px; } }
    .wizard-box .wizard-box-content .cell.wizard-cell {
      max-width: 480px; }
      @media screen and (min-width: 0) {
        .wizard-box .wizard-box-content .cell.wizard-cell {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; } }
      @media screen and (min-width: 640px) {
        .wizard-box .wizard-box-content .cell.wizard-cell {
          width: 480px;
          margin-left: 10%;
          margin-right: 10%; } }
      @media screen and (min-width: 1024px) {
        .wizard-box .wizard-box-content .cell.wizard-cell {
          width: 400px;
          margin-left: 20px;
          margin-right: 20px; } }
      @media screen and (min-width: 1200px) {
        .wizard-box .wizard-box-content .cell.wizard-cell {
          width: 480px;
          margin-left: 40px;
          margin-right: 40px; } }
      @media screen and (min-width: 1440px) {
        .wizard-box .wizard-box-content .cell.wizard-cell {
          width: 480px;
          margin-left: 80px;
          margin-right: 80px; } }
    .wizard-box .wizard-box-content .wizard-cell-stubborn {
      min-width: 480px;
      max-width: 480px; }
      @media screen and (max-width: 640px) {
        .wizard-box .wizard-box-content .wizard-cell-stubborn {
          min-width: inherit; } }
    @media screen and (max-width: 640px) {
      .wizard-box .wizard-box-content {
        vertical-align: top; } }
