.button-switch {
  height: 35px; }
  .button-switch.is-disabled .button-switch-option {
    cursor: not-allowed; }
  .button-switch .button-switch-option {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #197dc2;
    border-right: 0;
    color: #197dc2;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    height: 100%;
    min-width: 53px;
    padding-top: 7px; }
    .button-switch .button-switch-option.selected {
      background-color: #197dc2;
      color: #ffffff; }
    .button-switch .button-switch-option:first-child {
      border-radius: 6px 0 0 6px; }
    .button-switch .button-switch-option:last-child {
      border-radius: 0 6px 6px 0;
      border-right: 1px solid #197dc2; }
