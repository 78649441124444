.SecondNav .title-bar.second-nav-title-bar {
  background-color: #153e78;
  height: 57px; }

.SecondNav .second-nav-icons {
  padding-right: 20px; }
  .SecondNav .second-nav-icons.left-set {
    padding-left: 67px; }
    @media screen and (max-width: 1024px) {
      .SecondNav .second-nav-icons.left-set {
        padding-left: inherit; } }
  .SecondNav .second-nav-icons div.icon-link {
    display: inline-block;
    min-width: 162px;
    margin-right: 34px; }
    @media screen and (max-width: 1024px) {
      .SecondNav .second-nav-icons div.icon-link {
        min-width: unset; } }
    .SecondNav .second-nav-icons div.icon-link:last-child {
      margin-right: inherit; }
  .SecondNav .second-nav-icons a {
    color: #ffffff;
    text-decoration: none; }
    .SecondNav .second-nav-icons a .labeled-icon {
      display: inline-block; }
      .SecondNav .second-nav-icons a .labeled-icon .icon-label {
        margin-left: 30px;
        padding-top: 6px;
        font-size: 10px; }
        .SecondNav .second-nav-icons a .labeled-icon .icon-label.menu-label-txt {
          font-size: 10px;
          max-width: 52px;
          padding-top: 0; }

.SecondNav .navbar-tray {
  background-color: #172E4F;
  padding: 8px; }
  .SecondNav .navbar-tray .navbar-tray-item {
    padding-top: 8px;
    margin-left: 8px; }
