.user-notification-delay-preferences-panel .cell-divider {
  margin-top: 45px; }

.user-notification-delay-preferences-panel .delay-details {
  margin-top: 9px; }
  .user-notification-delay-preferences-panel .delay-details .delay-header {
    margin-left: -51px;
    margin-right: -51px; }
  .user-notification-delay-preferences-panel .delay-details .instructions {
    margin-bottom: 20px;
    font-size: 11px; }
  .user-notification-delay-preferences-panel .delay-details .delay-instructions {
    margin-bottom: 40px;
    font-size: 11px; }
  .user-notification-delay-preferences-panel .delay-details .use-delay-radio-cell input {
    margin-bottom: 8px; }
  .user-notification-delay-preferences-panel .delay-details .use-delay-radio-cell label {
    margin-top: -8px;
    font-size: 11px; }
  .user-notification-delay-preferences-panel .delay-details .delay-mins-container {
    width: 100%;
    position: relative; }
    .user-notification-delay-preferences-panel .delay-details .delay-mins-container input {
      height: 36px;
      border: 1px solid #197dc2;
      font-size: 13px;
      margin-bottom: 10px; }
      .user-notification-delay-preferences-panel .delay-details .delay-mins-container input.invalid {
        border: 1px solid #de4949;
        color: #4a4a4a; }
    .user-notification-delay-preferences-panel .delay-details .delay-mins-container .delay-mins-unit-label {
      position: absolute;
      font-size: 13px;
      color: #197dc2;
      top: 8px;
      right: 15px; }
    .user-notification-delay-preferences-panel .delay-details .delay-mins-container .delay-mins-instructions {
      font-size: 11px;
      color: #9b9b9b; }
      .user-notification-delay-preferences-panel .delay-details .delay-mins-container .delay-mins-instructions.error-instructions {
        color: #de4949; }
