.transfer-site-modal .step-title {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px; }

.transfer-site-modal .step-description {
  font-size: 12px; }

.transfer-site-modal .input-cell {
  margin-top: 20px; }

.transfer-site-modal .error-box {
  margin-top: 10px;
  font-size: 12px; }

.transfer-site-modal .keep-access-cell {
  margin-top: 40px;
  font-size: 12px; }
  .transfer-site-modal .keep-access-cell .keep-access-radio {
    margin-top: 30px; }

.transfer-site-modal .display-partner-list {
  margin-top: 20px;
  margin-left: 30px; }

.transfer-site-modal .divider-cell {
  margin-top: 50px; }

.transfer-site-modal .confirm-access-cell {
  margin-top: 40px; }

.transfer-site-modal .final-instructions {
  margin-top: 70px;
  margin-bottom: 15px; }

.transfer-site-modal .instructions {
  font-size: 12px; }
