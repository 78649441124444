.system-profile-owner-panel .labelled-cell-content {
  font-size: 11px;
  margin-bottom: 30px; }

.system-profile-owner-panel .top-half {
  padding-bottom: 18px; }

.system-profile-owner-panel .top-part {
  padding-bottom: 18px; }

.system-profile-owner-panel .bottom-part {
  border-top: 1px solid #d9d9d9;
  padding-top: 18px; }

.system-profile-owner-panel .partner-radio-list {
  list-style-type: none; }

.system-profile-owner-panel .partner-radio-list-section {
  margin-top: 20px; }

.system-profile-owner-panel .partner-buttons {
  padding-top: 28px; }

.system-profile-owner-panel .divider-cell {
  margin-top: 30px; }

.system-profile-owner-panel .instruction-list {
  margin-bottom: 0; }

.system-profile-owner-panel .instructions {
  font-size: 12px; }
