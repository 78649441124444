.systems-site-group {
  margin-top: 40px !important; }
  .systems-site-group .site-name {
    font-size: 18px;
    color: #4a4a4a;
    font-weight: bold; }
  .systems-site-group .site-address, .systems-site-group .site-tag {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #9b9b9b; }
