.notifications .notifications-content {
  margin-top: 25px; }
  .notifications .notifications-content .top-section {
    margin-bottom: 40px; }
    .notifications .notifications-content .top-section .legend-item {
      padding-left: 27px;
      display: table;
      height: 36px; }
      .notifications .notifications-content .top-section .legend-item .legend-icon-box {
        display: table-cell;
        vertical-align: middle; }
        .notifications .notifications-content .top-section .legend-item .legend-icon-box .legend-icon {
          width: 18px;
          height: 18px; }
          .notifications .notifications-content .top-section .legend-item .legend-icon-box .legend-icon.action-required-icon {
            color: #9b9b9b; }
      .notifications .notifications-content .top-section .legend-item .legend-text {
        display: table-cell;
        vertical-align: middle;
        padding-left: 15px;
        font-size: 12px;
        letter-spacing: -0.3; }
