.system-detail {
  height: 100%; }
  .system-detail .proxy-frame-container {
    height: 100% !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch; }
    .system-detail .proxy-frame-container .proxy-frame {
      border: 0;
      width: 100% !important;
      height: calc(100vh - 65px); }
