.titled-panel-section-header {
  background-color: #e5e5e5;
  min-height: 45px;
  margin-left: -36px;
  margin-right: -36px;
  padding-left: 36px;
  padding-top: 11px;
  margin-bottom: 36px; }
  .titled-panel-section-header .titled-panel-section-header-title {
    font-size: 11px;
    font-weight: bold; }
