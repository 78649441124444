.prism-check-box {
  height: 34px;
  width: 100%; }
  .prism-check-box input.prism-check-box-input {
    cursor: pointer;
    margin: 0;
    height: 20px; }
    .prism-check-box input.prism-check-box-input:disabled {
      cursor: not-allowed !important; }
  .prism-check-box label.prism-check-box-label {
    cursor: pointer !important;
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px; }
    .prism-check-box label.prism-check-box-label.disabled {
      cursor: not-allowed !important; }
  .prism-check-box .prism-check-box-post-label {
    padding-left: 4px; }
  .prism-check-box.styled-light-blue-square input.prism-check-box-input:not(old) {
    display: inline-block;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 25px; }
  .prism-check-box.styled-light-blue-square input.prism-check-box-input:not(old) + label.prism-check-box-label {
    display: inline-block;
    margin-left: -30px;
    padding-left: 35px;
    line-height: 25px;
    background: url("./images/checkboxes/light-blue-square-empty.svg") no-repeat; }
    .prism-check-box.styled-light-blue-square input.prism-check-box-input:not(old) + label.prism-check-box-label.empty-label {
      width: 25px;
      height: 25px; }
  .prism-check-box.styled-light-blue-square input.prism-check-box-input:not(old):checked + label.prism-check-box-label {
    background: url("./images/checkboxes/light-blue-square-checked.svg") no-repeat; }
  .prism-check-box.styled-light-blue-square input.prism-check-box-input:not(old):disabled + label.prism-check-box-label {
    opacity: 0.4; }
  .prism-check-box.styled-dark-blue-square input.prism-check-box-input:not(old) {
    display: inline-block;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 25px; }
  .prism-check-box.styled-dark-blue-square input.prism-check-box-input:not(old) + label.prism-check-box-label {
    display: inline-block;
    margin-left: -30px;
    padding-left: 35px;
    line-height: 25px;
    background: url("./images/checkboxes/dark-blue-square-empty.svg") no-repeat; }
    .prism-check-box.styled-dark-blue-square input.prism-check-box-input:not(old) + label.prism-check-box-label.empty-label {
      width: 25px;
      height: 25px; }
  .prism-check-box.styled-dark-blue-square input.prism-check-box-input:not(old):checked + label.prism-check-box-label {
    background: url("./images/checkboxes/dark-blue-square-checked.svg") no-repeat; }
  .prism-check-box.styled-dark-blue-square input.prism-check-box-input:not(old):disabled + label.prism-check-box-label {
    opacity: 0.4; }
  .prism-check-box.styled-dark-blue-circle input.prism-check-box-input:not(old) {
    display: inline-block;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 36px; }
  .prism-check-box.styled-dark-blue-circle input.prism-check-box-input:not(old) + label.prism-check-box-label {
    display: inline-block;
    margin-left: -30px;
    padding-left: 35px;
    line-height: 36px;
    background: url("./images/checkboxes/dark-blue-circle-empty.svg") no-repeat; }
    .prism-check-box.styled-dark-blue-circle input.prism-check-box-input:not(old) + label.prism-check-box-label.empty-label {
      width: 36px;
      height: 36px; }
  .prism-check-box.styled-dark-blue-circle input.prism-check-box-input:not(old):checked + label.prism-check-box-label {
    background: url("./images/checkboxes/dark-blue-circle-checked.svg") no-repeat; }
  .prism-check-box.styled-dark-blue-circle input.prism-check-box-input:not(old):disabled + label.prism-check-box-label {
    opacity: 0.4; }
  .prism-check-box.styled-switch input.prism-check-box-input:not(old) {
    display: inline-block;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 70px; }
  .prism-check-box.styled-switch input.prism-check-box-input:not(old) + label.prism-check-box-label {
    display: inline-block;
    margin-left: -75px;
    padding-left: 80px;
    line-height: 40px;
    background: url("./images/checkboxes/switch-off.svg") no-repeat; }
    .prism-check-box.styled-switch input.prism-check-box-input:not(old) + label.prism-check-box-label.empty-label {
      width: 70px;
      height: 36px; }
  .prism-check-box.styled-switch input.prism-check-box-input:not(old):checked + label.prism-check-box-label {
    background: url("./images/checkboxes/switch-on.svg") no-repeat; }
  .prism-check-box.styled-switch input.prism-check-box-input:not(old):disabled + label.prism-check-box-label {
    opacity: 0.4; }
