.reveal.captcha-catcher {
  width: 250px;
  height: 140px;
  text-align: center;
  border-radius: 12px; }
  .reveal.captcha-catcher .spinner-container {
    margin-top: 20px;
    width: 100%; }
    .reveal.captcha-catcher .spinner-container .spinner {
      position: absolute;
      left: 125px;
      margin-left: -25px; }
  .reveal.captcha-catcher .ready-icon {
    width: 50px;
    height: 50px; }
  .reveal.captcha-catcher .captcha-status-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #4a4a4a; }
