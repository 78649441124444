.add-site-step-1-modal .instructions {
  font-size: 12px; }

.add-site-step-1-modal .inputs {
  margin-top: 20px; }

.add-site-step-1-modal .error-box {
  margin-top: 10px;
  font-size: 12px; }

.add-site-step-1-modal .buttons {
  margin-top: 50px; }
