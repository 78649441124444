.user-mgmt .button-grid button {
  margin-bottom: 0; }

.user-mgmt .org-invitation-panel {
  margin-top: 40px;
  margin-bottom: 0; }

.user-mgmt .member-list {
  margin-top: 40px;
  margin-bottom: 0; }
