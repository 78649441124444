.invite-user-to-system-modal .invite-user-to-system-modal-content .text-cell {
  margin-top: 24px; }

.invite-user-to-system-modal .invite-user-to-system-modal-content .input-cell {
  margin-top: 40px; }

.invite-user-to-system-modal .invite-user-to-system-modal-content .error-box {
  margin-top: 20px;
  font-size: 11px; }

.invite-user-to-system-modal .invite-user-to-system-modal-content .spacer-div {
  margin-top: 40px; }

.invite-user-to-system-modal .invite-user-to-system-modal-content .invite-system-partners-text {
  font-size: 12px; }
