.labelled-input {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  font-size: 10px;
  margin-top: 20px;
  height: 36px;
  border: 1px #c6c6c6 solid; }
  .labelled-input.invalid {
    border: 1px #de4949 solid; }
    .labelled-input.invalid input {
      border: 0; }
      .labelled-input.invalid input:focus {
        border: 0; }
  .labelled-input input {
    box-shadow: unset;
    -webkit-box-shadow: unset;
    height: 34px;
    width: inherit !important;
    min-width: 100px;
    font-size: 12px;
    background-color: #ffffff;
    border: 0;
    color: #000000;
    flex-grow: 1;
    padding: 0;
    margin-left: 23px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0; }
    .labelled-input input:focus {
      background-color: #ffffff;
      outline: none;
      box-shadow: none;
      border: 0; }
  .labelled-input .invalid {
    color: #de4949; }
  .labelled-input .invalid:focus {
    color: #163e78; }
  .labelled-input label {
    background-color: #ffffff;
    color: #c6c6c6;
    font-size: 10px;
    font-weight: 500;
    padding-left: 10px;
    text-align: left;
    line-height: 1.1em;
    white-space: nowrap; }

select.prism-select.with-labelled {
  margin-top: 20px;
  margin-bottom: 0;
  display: flex;
  border: 1px solid #c6c6c6;
  color: #000000;
  box-shadow: unset;
  -webkit-box-shadow: unset; }
  select.prism-select.with-labelled:focus {
    background-color: #ffffff;
    outline: none;
    box-shadow: none; }
