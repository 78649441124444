.titled-panel.callout {
  border-radius: 0;
  border: 0;
  padding: 36px; }

.titled-panel .title-bar {
  background-color: #197dc2;
  height: 45px;
  padding: 0;
  margin-bottom: 36px !important;
  margin-left: -36px !important;
  margin-right: -36px !important;
  margin-top: -36px !important; }

.titled-panel .titled-panel-title {
  font-size: 11px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 36px;
  margin-right: 36px; }
