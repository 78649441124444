.labelled-cell {
  padding-top: 9px; }
  .labelled-cell .labelled-cell-label-text {
    font-size: 11px;
    min-height: 20px; }
  .labelled-cell .labelled-cell-content {
    padding-top: 20px; }
  .labelled-cell input.labelled-cell-input:not([type="checkbox"]) {
    height: 36px;
    font-size: 13px;
    border: 1px solid #197dc2; }
    .labelled-cell input.labelled-cell-input:not([type="checkbox"]):disabled {
      opacity: 0.4;
      background-color: #ffffff; }
    .labelled-cell input.labelled-cell-input:not([type="checkbox"]).invalid {
      border: 1px solid #de4949; }
  .labelled-cell .labelled-cell-input-error-box {
    font-size: 12px; }
