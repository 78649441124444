.marketing-box {
  position: relative;
  margin: 0;
  padding-bottom: 144px;
  min-height: 100vh;
  width: 100vw;
  background-color: #ffffff; }
  .marketing-box *,
  .marketing-box *:before,
  .marketing-box *:after {
    box-sizing: inherit; }
  .marketing-box .marketing-box-link-button {
    width: 154px;
    height: 36px;
    margin-right: 34px; }
    .marketing-box .marketing-box-link-button.current-route {
      color: #ffffff;
      background-color: #5abde8; }
      .marketing-box .marketing-box-link-button.current-route:hover {
        color: #ffffff;
        background-color: #5abde8; }
      .marketing-box .marketing-box-link-button.current-route:disabled {
        opacity: initial; }
    .marketing-box .marketing-box-link-button.other-route {
      color: #ffffff;
      border: 1px solid #ffffff; }
      .marketing-box .marketing-box-link-button.other-route:hover {
        color: #9b9b9b;
        border-color: #9b9b9b; }
    .marketing-box .marketing-box-link-button:last-child {
      margin-right: 0; }
  .marketing-box .top-cell {
    height: 14vw;
    max-height: 144px;
    background: url("./images/slanty-box-top.svg");
    background-size: 100% 100%; }
    .marketing-box .top-cell .top-btn-container {
      width: 100%;
      text-align: right;
      padding-right: 36px;
      padding-top: 42px; }
      @media screen and (max-width: 1024px) {
        .marketing-box .top-cell .top-btn-container {
          padding-right: 16px;
          padding-top: 10px; } }
  .marketing-box .small-post-top-cell {
    width: 154px;
    margin-left: calc(50vw - 77px);
    margin-bottom: 46px; }
    .marketing-box .small-post-top-cell .marketing-box-link-button.other-route {
      color: #5abde8;
      border: 1px solid #5abde8; }
      .marketing-box .small-post-top-cell .marketing-box-link-button.other-route:hover {
        color: #197dc2;
        border-color: #197dc2; }
  .marketing-box .small-pre-bottom-cell {
    position: absolute;
    right: 0;
    bottom: 14vw;
    left: 0;
    height: 48px; }
    .marketing-box .small-pre-bottom-cell .small-logo {
      width: 154px;
      height: 48px;
      margin-left: calc(50% - 77px); }
  .marketing-box .bottom-cell {
    height: 14vw;
    max-height: 144px;
    background: url("./images/slanty-box-bottom.svg");
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; }
    .marketing-box .bottom-cell .logo-container {
      position: absolute;
      bottom: 33px;
      left: 57px; }
      @media screen and (max-width: 1024px) {
        .marketing-box .bottom-cell .logo-container {
          width: 480px;
          left: calc(50% - 95.6px); } }
  .marketing-box .marketing-box-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap; }
    @media screen and (max-width: 1024px) {
      .marketing-box .marketing-box-content {
        flex-wrap: wrap; } }
    .marketing-box .marketing-box-content .marketing-left {
      width: 45vw;
      max-width: 470px;
      min-width: 470px; }
      @media screen and (max-width: 1024px) {
        .marketing-box .marketing-box-content .marketing-left {
          width: 90vw;
          min-width: unset; } }
    .marketing-box .marketing-box-content .marketing-right {
      width: 45vw;
      max-width: 470px;
      min-width: 470px; }
      @media screen and (max-width: 1024px) {
        .marketing-box .marketing-box-content .marketing-right {
          width: 90vw;
          min-width: unset; } }
    .marketing-box .marketing-box-content .marketing-spacer {
      display: inline-block;
      width: 10vw;
      max-width: 155px; }
      @media screen and (max-width: 1024px) {
        .marketing-box .marketing-box-content .marketing-spacer {
          display: none;
          width: 0;
          max-width: unset; } }
