.register-system-modal .register-system-wizard-box {
  background-color: #163e78; }
  .register-system-modal .register-system-wizard-box.wizard-box-content {
    vertical-align: top; }
  .register-system-modal .register-system-wizard-box .top {
    margin-top: 34px;
    text-align: right; }
    .register-system-modal .register-system-wizard-box .top .close-icon {
      padding-right: 34px; }
  .register-system-modal .register-system-wizard-box .title {
    margin-top: 20vh;
    font-size: 16px;
    font-weight: 600; }
  .register-system-modal .register-system-wizard-box .instructions {
    margin-top: 4.8vh;
    font-size: 14px; }
  .register-system-modal .register-system-wizard-box .digits {
    margin-top: 7.8vh;
    z-index: 1; }
    .register-system-modal .register-system-wizard-box .digits .invalid-icon {
      position: relative;
      top: -75px;
      left: 300px;
      z-index: -1; }
      @media screen and (max-width: 640px) {
        .register-system-modal .register-system-wizard-box .digits .invalid-icon {
          display: none; } }
  .register-system-modal .register-system-wizard-box .register-system-error {
    display: inline-block;
    width: 80vw;
    max-width: 530px;
    text-align: left;
    background-color: #de4949;
    color: #ffffff;
    font-weight: bold; }
    .register-system-modal .register-system-wizard-box .register-system-error .error-container {
      display: flex;
      flex-direction: row;
      align-items: left;
      vertical-align: middle; }
      .register-system-modal .register-system-wizard-box .register-system-error .error-container .error-icon {
        padding-top: 5px; }
      .register-system-modal .register-system-wizard-box .register-system-error .error-container .error-message {
        padding-left: 20px; }
  .register-system-modal .register-system-wizard-box .error-box {
    margin-top: 5.8vh;
    font-size: 14px; }
  .register-system-modal .register-system-wizard-box .help {
    margin-top: 5.8vh;
    font-size: 14px;
    font-weight: 300; }
    .register-system-modal .register-system-wizard-box .help a {
      color: #6ecdf7;
      text-decoration: none; }
      .register-system-modal .register-system-wizard-box .help a:hover {
        text-decoration: underline; }
  .register-system-modal .register-system-wizard-box .spinner-container {
    width: 100%; }
    .register-system-modal .register-system-wizard-box .spinner-container .spinner {
      position: absolute;
      left: 50vw;
      margin-left: -25px; }
