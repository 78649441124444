.sms-phone-modal .sms-phone-modal-content .instructions {
  margin-top: 15px;
  font-size: 12px; }

.sms-phone-modal .sms-phone-modal-content .phone-input-cell, .sms-phone-modal .sms-phone-modal-content .verify-input-cell {
  margin-top: 60px;
  margin-bottom: 60px; }
  .sms-phone-modal .sms-phone-modal-content .phone-input-cell .phone-input, .sms-phone-modal .sms-phone-modal-content .phone-input-cell .code-input, .sms-phone-modal .sms-phone-modal-content .verify-input-cell .phone-input, .sms-phone-modal .sms-phone-modal-content .verify-input-cell .code-input {
    text-align: center;
    font-size: 13px;
    height: 34px; }

.sms-phone-modal .sms-phone-modal-content .cancel-btn, .sms-phone-modal .sms-phone-modal-content .next-btn {
  width: 130px; }
  @media screen and (max-width: 640px) {
    .sms-phone-modal .sms-phone-modal-content .cancel-btn, .sms-phone-modal .sms-phone-modal-content .next-btn {
      width: 100%; } }
