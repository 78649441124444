.create-org-maybe .info-text {
  margin-top: 99px;
  font-size: 13px; }
  .create-org-maybe .info-text a {
    color: #72cef5; }

.create-org-maybe .button-box {
  margin-top: 87px; }
  .create-org-maybe .button-box .logout-btn {
    min-width: 150px; }
