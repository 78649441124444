.remove-system-modal .instructions {
  margin-top: 40px;
  font-size: 12px; }

.remove-system-modal .keep-access-radios {
  margin-top: 40px; }

.remove-system-modal .divider-cell {
  margin-top: 20px; }

.remove-system-modal .display-partner-list {
  margin-top: 20px;
  margin-left: 30px; }
