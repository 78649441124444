.add-user-modal .add-user-modal-content .instructions {
  font-size: 12px; }
  .add-user-modal .add-user-modal-content .instructions.step-1 {
    margin-top: 4px; }
  .add-user-modal .add-user-modal-content .instructions.step-2 {
    margin-top: 60px; }

.add-user-modal .add-user-modal-content .input-cell {
  margin-top: 40px; }

.add-user-modal .add-user-modal-content .divider-cell {
  margin-top: 40px; }

.add-user-modal .add-user-modal-content .error-box {
  font-size: 12px;
  padding-top: 10px; }

.add-user-modal .add-user-modal-content .cancel-invite-button, .add-user-modal .add-user-modal-content .send-invite-button {
  margin-bottom: 0; }
