.create-account .page-heading {
  font-size: 20px;
  color: #163e78; }
  @media screen and (max-width: 1024px) {
    .create-account .page-heading {
      text-align: center;
      margin-top: 20px; } }

.create-account .pw-instructions {
  font-size: 10px;
  font-weight: 300;
  padding-top: 20px;
  color: #000000;
  line-height: 1.1; }

.create-account .error-box {
  margin-top: 19px;
  font-size: 12px;
  font-weight: 600; }

.create-account .button-box {
  margin-top: 18px;
  text-align: right;
  font-size: 10px; }
  @media screen and (max-width: 640px) {
    .create-account .button-box {
      text-align: center;
      margin-top: 40px; } }
  .create-account .button-box .create-btn {
    width: 206px; }

.create-account .terms-and-such {
  margin-top: 35px; }
  .create-account .terms-and-such .terms-check {
    margin-bottom: 10px; }
    .create-account .terms-and-such .terms-check:last-child {
      margin-bottom: initial; }
  .create-account .terms-and-such .create-account-cb-label {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #000000;
    margin-right: 0;
    padding-right: 0; }
    .create-account .terms-and-such .create-account-cb-label a {
      font-size: 13px;
      font-weight: 600;
      color: #5abde8; }
