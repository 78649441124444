.marketing-left {
  text-align: center; }
  .marketing-left .intelligen-color {
    margin-top: 50px; }
    @media screen and (max-width: 640px) {
      .marketing-left .intelligen-color {
        margin-top: 0;
        height: 50px;
        width: 167.5px; } }
  .marketing-left .simplicity-img {
    margin-top: 45px; }
    @media screen and (max-width: 1024px) {
      .marketing-left .simplicity-img {
        max-width: 60%;
        height: auto;
        clear: both; } }
  .marketing-left .colorbar-img {
    margin-top: 28px; }
    @media screen and (max-width: 1024px) {
      .marketing-left .colorbar-img {
        max-width: 60%;
        height: auto;
        clear: both; } }
  .marketing-left .introducing-text {
    margin-top: 22px;
    font-size: 24px;
    color: #000000; }
    @media screen and (max-width: 1024px) {
      .marketing-left .introducing-text {
        font-size: 22px; } }
  .marketing-left .visit-text {
    font-size: 14px;
    font-weight: 600;
    margin-top: 67px;
    color: #000000; }
    @media screen and (max-width: 1024px) {
      .marketing-left .visit-text {
        margin-top: 22px; } }
