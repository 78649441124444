.edit-profile .edit-profile-content {
  margin: 0; }
  .edit-profile .edit-profile-content .name-header {
    color: #197dc2;
    font-size: 18px;
    font-weight: bold; }
  .edit-profile .edit-profile-content .org-name-header {
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 600; }
  .edit-profile .edit-profile-content .button-grid {
    margin-bottom: 27px; }
  .edit-profile .edit-profile-content .profile-panel .titled-panel {
    margin-bottom: 40px; }

.edit-profile .change-pw-btn {
  width: 200px; }
