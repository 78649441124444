.manage-system-item .reorder-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .manage-system-item .reorder-buttons-wrapper .up-button {
    width: 30px;
    min-height: 20px;
    margin-bottom: 2px;
    padding: 0; }
  .manage-system-item .reorder-buttons-wrapper .down-button {
    width: 30px;
    min-height: 20px;
    padding: 0; }
