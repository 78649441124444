.system-profile-unregister-panel .unregister-detailed-list-section {
  margin-top: 20px;
  margin-bottom: 20px; }

.system-profile-unregister-panel .unregister-detailed-list {
  list-style-type: disc;
  font-size: 11px;
  padding-top: 10px; }

.system-profile-unregister-panel .half-circle-spinner {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 168px; }

.system-profile-unregister-panel .yes-or-no-message {
  margin-top: 14px; }

.system-profile-unregister-panel .yes-or-no-buttons {
  margin-top: 30px; }
