.manage-systems-site-box {
  margin-top: 40px !important; }
  .manage-systems-site-box .site-name {
    font-size: 18px;
    color: #4a4a4a;
    font-weight: bold; }
  .manage-systems-site-box .system-count {
    font-size: 11px;
    color: #9b9b9b;
    font-weight: bold;
    text-align: right; }
  .manage-systems-site-box .site-address, .manage-systems-site-box .site-tag {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #9b9b9b; }
