.forgot-password .marketing-left {
  text-align: left; }

.forgot-password .page-heading {
  margin-top: 120px;
  font-size: 24px;
  font-weight: bold;
  color: #163e78; }
  @media screen and (max-width: 1024px) {
    .forgot-password .page-heading {
      margin-top: 75px;
      font-size: 22px; } }
  @media screen and (max-width: 640px) {
    .forgot-password .page-heading {
      margin-top: 20px;
      font-size: 18px; } }

.forgot-password .forgot-instructions {
  font-size: 13px;
  padding-top: 20px;
  color: #000000;
  padding-right: 0;
  margin-right: 0; }

.forgot-password .inputs {
  margin-top: 52px; }

.forgot-password .having-trouble {
  font-size: 14px;
  margin-top: 36px;
  color: #000000; }
  .forgot-password .having-trouble a {
    font-weight: bold;
    color: #000000; }

.forgot-password .button-box {
  margin-top: 33px;
  text-align: right; }
  .forgot-password .button-box .send-btn {
    min-width: 165px; }
  @media screen and (max-width: 640px) {
    .forgot-password .button-box {
      text-align: center; } }

.forgot-password .error-box {
  margin-top: 38px;
  font-size: 12px;
  font-weight: 600; }
