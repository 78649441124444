.password-change .change-desc {
  margin-top: 120px;
  font-size: 24px;
  font-weight: bold;
  color: #163e78; }
  @media screen and (max-width: 1024px) {
    .password-change .change-desc {
      margin-top: 75px;
      font-size: 22px; } }
  @media screen and (max-width: 640px) {
    .password-change .change-desc {
      margin-top: 20px;
      font-size: 18px; } }

.password-change .pw-instructions {
  margin-top: 20px;
  font-size: 11px;
  font-weight: normal; }

.password-change .error-box {
  font-weight: bold;
  min-height: 60px;
  margin-bottom: 20px; }

.password-change .button-box {
  text-align: right; }
  .password-change .button-box .change-btn {
    min-width: 165px; }
  @media screen and (max-width: 640px) {
    .password-change .button-box {
      text-align: center; } }
