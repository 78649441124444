.AdminBar {
  background-color: #de4949;
  color: #ffffff;
  min-height: 57px;
  padding: 0.5rem; }
  .AdminBar .admin-message {
    padding-left: 74px;
    padding-right: 74px;
    padding-top: 12px;
    padding-bottom: 12px; }
    @media screen and (max-width: 1024px) {
      .AdminBar .admin-message {
        padding-left: 12px;
        padding-right: 12px; } }
