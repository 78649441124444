.org-profile .org-profile-content {
  margin-top: 0; }
  .org-profile .org-profile-content .org-name-header {
    color: #197dc2;
    font-size: 18px;
    font-weight: bold; }
  .org-profile .org-profile-content .org-stat-keys {
    display: inline-block;
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 600; }
  .org-profile .org-profile-content .org-stat-vals {
    display: inline-block;
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 600;
    margin-left: 20px; }
  .org-profile .org-profile-content .org-profile-info {
    margin-top: 39px; }
  .org-profile .org-profile-content .mandatory-cell {
    margin-top: 25px;
    font-size: 11px; }
