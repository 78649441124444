.user-sms-preferences-panel .spacer-cell {
  margin-top: 45px; }

.user-sms-preferences-panel .add-number-btn {
  width: 200px; }

.user-sms-preferences-panel input.sms-number {
  font-size: 13px; }
  .user-sms-preferences-panel input.sms-number:disabled {
    border: 1px solid #197dc2; }
