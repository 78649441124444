.TopNav {
  border-bottom: 3px solid #34bbf4; }
  .TopNav .site-system {
    display: inline-block;
    margin-left: 20px; }
  .TopNav .title-bar.top-nav-title-bar {
    background-color: #197dc2;
    height: 57px; }
  .TopNav .top-nav-icons.left-set {
    padding-left: 67px; }
    @media screen and (max-width: 1024px) {
      .TopNav .top-nav-icons.left-set {
        padding-left: inherit; } }
  .TopNav .top-nav-icons.right-set {
    padding-right: 67px; }
    @media screen and (max-width: 1024px) {
      .TopNav .top-nav-icons.right-set {
        padding-right: inherit; } }
  .TopNav .top-nav-icons div.icon-link {
    display: inline-block;
    margin-left: 40px;
    min-width: 40px; }
    @media screen and (max-width: 1024px) {
      .TopNav .top-nav-icons div.icon-link {
        margin-left: 25px; }
        .TopNav .top-nav-icons div.icon-link:first-child {
          margin-left: inherit; } }
    .TopNav .top-nav-icons div.icon-link:first-child {
      margin-left: inherit; }
  .TopNav .top-nav-icons a {
    color: #ffffff;
    text-decoration: none; }
    .TopNav .top-nav-icons a .labeled-icon {
      display: inline-block; }
      .TopNav .top-nav-icons a .labeled-icon .icon-label {
        margin-left: 30px;
        padding-top: 6px;
        font-size: 10px; }
        .TopNav .top-nav-icons a .labeled-icon .icon-label.menu-label-txt {
          font-size: 10px;
          max-width: 52px;
          padding-top: 0; }
  .TopNav .navbar-tray {
    background-color: #172E4F;
    padding: 8px; }
    .TopNav .navbar-tray .navbar-tray-item {
      padding-top: 8px;
      margin-left: 8px; }
