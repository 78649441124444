.PrismToaster {
  z-index: 999; }
  .PrismToaster .toast {
    position: relative;
    min-width: 300px;
    max-width: 85vw;
    padding-right: 50px;
    border: 0;
    border-radius: 12px;
    box-shadow: 3px 4px 4px 0 rgba(188, 188, 188, 0.5); }
    @media screen and (max-width: 640px) {
      .PrismToaster .toast {
        min-width: 250px; } }
    .PrismToaster .toast .toast-close-button {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 10px;
      top: 10px;
      cursor: pointer;
      background-image: url("./images/close-toast.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%; }
    .PrismToaster .toast .toast-content {
      font-size: 14px;
      padding-top: 7px;
      padding-bottom: 7px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word; }
      .PrismToaster .toast .toast-content a {
        color: #197dc2;
        font-weight: 600; }
      .PrismToaster .toast .toast-content .toast-icon {
        font-size: 25px;
        padding-top: 10px;
        padding-right: 20px; }
