.collapsible-grid-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 60px !important; }
  .collapsible-grid-row:last-child {
    border-bottom: 0; }
  .collapsible-grid-row .collapsible-grid-row-data {
    font-size: 12px; }
    .collapsible-grid-row .collapsible-grid-row-data .button {
      margin-bottom: 0; }
  .collapsible-grid-row .collapsible-grid-header {
    padding-top: 10px;
    padding-bottom: 10px; }
