.add-site-step-2-modal .add-site-step-2-modal-content .instructions {
  font-size: 12px; }

.add-site-step-2-modal .add-site-step-2-modal-content .divider-cell {
  margin-top: 20px; }

.add-site-step-2-modal .add-site-step-2-modal-content .error-box {
  margin-top: 10px;
  font-size: 12px; }

.add-site-step-2-modal .add-site-step-2-modal-content .buttons {
  margin-top: 60px; }
