.site-systems-panel .labelled-cell-content {
  font-size: 13px; }

.site-systems-panel .titled-panel-section-header {
  margin-top: 37px; }

.site-systems-panel .site-system-row {
  border-bottom: 1px solid #d9d9d9;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center; }
  .site-systems-panel .site-system-row:last-child {
    border-bottom: 0; }

.site-systems-panel .bottom-part {
  border-top: 1px solid #d9d9d9;
  padding-top: 18px; }

.site-systems-panel .system-count {
  font-size: 11px;
  color: #9b9b9b;
  font-weight: bold;
  text-align: right;
  margin-top: 10px; }

.site-systems-panel .top-part {
  padding-bottom: 18px; }

.site-systems-panel .divider {
  border-top: 1px solid #d9d9d9;
  padding-top: 18px; }

.site-systems-panel .partner-buttons {
  padding-top: 28px; }

.site-systems-panel .collapsible-grid-divider {
  width: 100%;
  border-bottom: 2px solid #d9d9d9;
  margin-top: 40px;
  margin-bottom: 40px; }

.site-systems-panel .table-header-label {
  color: #9b9b9b;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase; }

.site-systems-panel .add-system-section {
  font-size: 11px; }

.site-systems-panel .system-list {
  font-size: 11px; }
