.general-page {
  padding-top: 57px;
  min-height: calc(100vh - 117px);
  position: relative;
  margin: 0;
  padding-bottom: calc(114px + 140px); }
  .general-page *,
  .general-page *:before,
  .general-page *:after {
    box-sizing: inherit; }
  .general-page .general-title-cell .title-text {
    color: #163e78;
    font-size: 24px;
    font-weight: bold; }
  .general-page .general-message-cell {
    min-height: 27px; }
    .general-page .general-message-cell .page-message {
      color: #197dc2;
      font-size: 18px; }
  .general-page .general-subtext-cell .sub-text {
    color: #9b9b9b;
    font-size: 10px;
    font-weight: 600; }
  .general-page .general-page-content {
    margin-top: 40px; }
  .general-page .general-page-empty-message {
    margin-top: 15vh; }
  .general-page .general-footer {
    height: 114px;
    background-color: #c6c6c6;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap; }
    .general-page .general-footer a {
      color: #4a4a4a; }
    @media screen and (max-width: 640px) {
      .general-page .general-footer {
        justify-content: center;
        flex-wrap: wrap;
        height: 160px; } }
    .general-page .general-footer .heatcraft-logo-box {
      display: inline-block;
      margin-top: 45px;
      margin-left: 9.7vw; }
      @media screen and (max-width: 640px) {
        .general-page .general-footer .heatcraft-logo-box {
          width: 100%;
          margin-left: 0;
          margin-top: 15px;
          text-align: center; } }
      .general-page .general-footer .heatcraft-logo-box .heatcraft-logo {
        width: 123px;
        height: 48px; }
    .general-page .general-footer .contact-info {
      display: inline-block;
      margin-top: 46px;
      margin-left: 23vw;
      font-size: 11px;
      font-weight: 300; }
      @media screen and (max-width: 1024px) {
        .general-page .general-footer .contact-info {
          margin-left: 9.7vw; } }
      @media screen and (max-width: 640px) {
        .general-page .general-footer .contact-info {
          width: 100%;
          margin-left: 0;
          margin-top: 15px;
          text-align: center; } }
    .general-page .general-footer .other-info {
      display: inline-block;
      margin-top: 46px;
      margin-left: 40px;
      font-size: 11px;
      font-weight: 300; }
      @media screen and (max-width: 1024px) {
        .general-page .general-footer .other-info {
          margin-left: 9.7vw; } }
      @media screen and (max-width: 640px) {
        .general-page .general-footer .other-info {
          width: 100%;
          margin-left: 0;
          margin-top: 15px;
          text-align: center; } }
