.system-profile .system-profile-content {
  margin-top: 0; }

.system-profile .site-name-header {
  font-size: 18px;
  color: #197dc2;
  font-weight: bold; }

.system-profile .site-address-header {
  font-size: 10px;
  color: #9b9b9b;
  font-weight: 600;
  letter-spacing: 0.1px; }

.system-profile .system-panel {
  margin-top: 40px; }
  .system-profile .system-panel .titled-panel {
    margin-bottom: 0; }

.system-profile .system-info-panel .details {
  margin-top: 10px;
  font-size: 11px; }

.system-profile .system-info-panel .system-name {
  margin-top: 20px; }
  .system-profile .system-info-panel .system-name .system-name-display-cell input.system-name-display {
    height: 36px;
    font-size: 13px;
    border: 1px solid #197dc2; }
