.edit-site-partner-panel .labelled-cell-content {
  font-size: 11px;
  margin-bottom: 30px;
  word-wrap: break-word; }

.edit-site-partner-panel .bottom-part {
  border-top: 1px solid #d9d9d9;
  padding-top: 18px; }

.edit-site-partner-panel .partner-count {
  font-size: 11px;
  color: #9b9b9b;
  font-weight: bold;
  text-align: right;
  margin-top: 10px; }

.edit-site-partner-panel .top-part {
  padding-bottom: 18px; }

.edit-site-partner-panel .partner-buttons {
  padding-top: 28px; }

.edit-site-partner-panel .invite-partners {
  margin-bottom: 40px;
  padding-top: 20px; }
