.edit-site-owner-panel .labelled-cell-content {
  font-size: 11px;
  margin-bottom: 30px; }

.edit-site-owner-panel .top-half {
  padding-bottom: 18px; }

.edit-site-owner-panel .top-part {
  padding-bottom: 18px; }

.edit-site-owner-panel .bottom-part {
  border-top: 1px solid #d9d9d9;
  padding-top: 18px; }

.edit-site-owner-panel .partner-radio-list {
  list-style-type: none; }

.edit-site-owner-panel .partner-radio-list-section {
  margin-top: 20px; }

.edit-site-owner-panel .invite-cancel-buttons {
  padding-top: 28px; }

.edit-site-owner-panel .display-partner-list {
  margin-top: 20px;
  margin-left: 30px; }

.edit-site-owner-panel .divider-cell {
  margin-top: 30px; }

.edit-site-owner-panel .confirm-access-cell {
  margin-top: 40px; }

.edit-site-owner-panel .final-instructions {
  margin-top: 70px;
  margin-bottom: 15px; }

.edit-site-owner-panel .instruction-list {
  margin-bottom: 0; }

.edit-site-owner-panel .instructions {
  font-size: 12px; }
