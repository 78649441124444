.status-bead {
  display: inline-block;
  width: 34px;
  min-width: 34px;
  height: 6px;
  min-height: 6px;
  border-radius: 2.5px; }
  .status-bead.status-bead-color-green {
    background-color: #2bd02b; }
  .status-bead.status-bead-color-red {
    background-color: #de4949; }
  .status-bead.status-bead-color-yellow {
    background-color: #fecc0b; }
  .status-bead.status-bead-color-gray {
    background-color: #cccccc; }
