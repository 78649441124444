.add-systems-modal {
  font-size: 12px; }
  .add-systems-modal .instructions {
    margin-top: 5px;
    font-size: 12px; }
  .add-systems-modal .system-radio-list {
    list-style-type: none; }
  .add-systems-modal .system-radio-section {
    margin-top: 20px; }
  .add-systems-modal .step-2 .instructions {
    margin-top: 15px; }
  .add-systems-modal .step-2 .buttons {
    padding-top: 70px; }
  .add-systems-modal .site-owner-align {
    margin-left: 33px; }
  .add-systems-modal .sys-org-align {
    margin-left: 10px; }
  .add-systems-modal .buttons {
    padding-top: 36px; }
  .add-systems-modal .divider-cell {
    margin-top: 50px; }
  .add-systems-modal p {
    margin-bottom: 0; }
