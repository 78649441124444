.create-org.wizard-box-content {
  vertical-align: top;
  background-color: #f1f1f1;
  color: #000000; }
  .create-org.wizard-box-content .labelled-input:not(.invalid) {
    border: 1px solid #72cef5; }

.create-org .top-grid {
  margin-top: 5vh; }

.create-org .error-box {
  margin-top: 88px;
  font-weight: bold;
  font-size: 12px; }
  @media screen and (max-width: 1024px) {
    .create-org .error-box {
      margin-top: 12px; } }

.create-org .setup-instructions {
  font-size: 20px;
  font-weight: bold; }

.create-org .detail-instructions {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px; }

.create-org .input-grid {
  margin-top: 20px; }

.create-org .column-heading {
  font-size: 16px;
  font-weight: 600;
  color: #197dc2;
  margin-top: 20px; }
  @media screen and (max-width: 1024px) {
    .create-org .column-heading {
      display: none; } }

.create-org .country-select {
  margin-bottom: 0;
  border: 1px solid #72cef5;
  width: 50%;
  min-width: 200px;
  margin-top: 6px; }
  @media screen and (max-width: 1024px) {
    .create-org .country-select {
      margin-top: 20px; } }

.create-org .last-grid {
  margin-top: 5vh; }
  @media screen and (max-width: 1024px) {
    .create-org .last-grid {
      margin-top: unset; } }

.create-org .mandatory-text {
  font-size: 10px;
  width: 100%;
  text-align: left; }

.create-org .top-banner {
  background-color: #197dc2;
  color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px; }
  .create-org .top-banner .option a {
    color: #ffffff;
    text-decoration: none; }
  .create-org .top-banner .option.disabled {
    opacity: 0.4 !important;
    cursor: not-allowed; }
    .create-org .top-banner .option.disabled a {
      opacity: 0.4 !important;
      cursor: not-allowed; }
  .create-org .top-banner .option .option-label {
    display: inline;
    font-size: 12px;
    font-weight: 600; }
  .create-org .top-banner .option .option-icon {
    padding-left: 20px;
    display: inline; }
  .create-org .top-banner .working {
    font-size: 10px; }
  .create-org .top-banner .spacer {
    height: 15vh;
    min-height: 30px;
    max-height: 50px; }
