.pin-input .pin-input-container input {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: center;
  max-width: 480px;
  height: 90px;
  background-color: #163e78;
  border: solid 2px #34bbf4;
  border-radius: 10px;
  color: #ffffff;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0.1em; }
  .pin-input .pin-input-container input:disabled {
    background-color: #3d5c87; }
  .pin-input .pin-input-container input.invalid {
    background-color: #163e78;
    color: #ffffff;
    border: solid 2px #de4949; }
    .pin-input .pin-input-container input.invalid:focus {
      background-color: #163e78; }
    .pin-input .pin-input-container input.invalid:disabled {
      background-color: #3d5c87; }
  .pin-input .pin-input-container input:focus {
    background-color: #163e78; }

.react-code-input {
  display: inline-block; }
  .react-code-input input:focus {
    background-color: #0964a3 !important;
    border: solid 3px #72cef5 !important;
    box-shadow: unset !important; }
  .react-code-input input:disabled {
    background-color: #3d5c87 !important; }
  .react-code-input input.invalid {
    background-color: #163e78 !important;
    color: #ffffff !important;
    border: solid 2px #de4949 !important; }
    .react-code-input input.invalid:focus {
      background-color: #163e78 !important; }
    .react-code-input input.invalid:disabled {
      background-color: #3d5c87 !important; }
