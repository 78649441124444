.notification-link .badged-icon-container {
  position: relative; }
  .notification-link .badged-icon-container .notification-badge {
    position: absolute;
    top: -7px;
    left: 12px;
    background-color: #ffffff;
    border-radius: 8px;
    color: #197dc2;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
    box-shadow: 0 1px 2px 0 rgba(109, 109, 109, 0.5); }
