.add-site-partner-modal .instructions {
  font-size: 12px;
  margin-top: 25px; }

.add-site-partner-modal .inputs {
  margin-top: 25px; }

.add-site-partner-modal .error-box {
  margin-top: 10px;
  font-size: 12px; }

.add-site-partner-modal .divider-cell {
  margin-top: 40px; }
